.image {
    mix-blend-mode: soft-light;
    position: absolute;
    background-size: cover;
    opacity: 0.45;
    width: 100%;
    height: 100%;
}

@media(min-width: 960px){

    .image {
        opacity: 0.6;
    }

    .fadeLeft {
        mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.25) 20%,
        rgba(0, 0, 0, 0.75) 30%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 1));
    }

    .statementContent__fadeLeft, .statementContent__fadeRight {
        width: 60%;
    }
    .statementContent__fadeLeft {
        float: left;
    }
    .statementContent__fadeRight {
        float: right;
    }

    .fadeRight {
        mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0.75) 70%,
        rgba(0, 0, 0, 0.25) 80%,
        rgba(0, 0, 0, 0));  
    }
}