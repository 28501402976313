.container {
    background: linear-gradient(#F8ECD9, #F0FBFF);
    display: flex;
    padding: 2em;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.spacer {
    display: none;
    flex-grow: 1;
    max-width: 200px;
}

.navContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    flex-grow: 1;
}
.navLink {
    font-size: 1.5em;
    color: #004884;
    font-family: 'Merriweather Sans', sans-serif;
    cursor: pointer;
    text-align: center;
    padding: 0.25em;
}

.logo {
    height: auto;
    width: calc(100vw - 4em);
    max-width: 400px;
}

@media(min-width: 400px) {
    .navLink {
        font-size: 2em;
    }
}

@media(min-width: 640px) {

    .logo {
        height: 8em;
        width: auto;
        max-width: unset;
    }

    .navContainer {
        flex-direction: row;
        justify-content: space-around;
    }

}

@media(min-width: 960px) {
    .container {
        padding: 3em 6em;
    }
    .container {
        flex-direction: row;
    }
    .spacer {
        display: block;
    }
    .navContainer {
        margin-top: 0;
        width: auto;
    }
}
@media(min-width: 1280px) {
    .container {
        padding: 4em 8em 6em;
    }
    .navContainer {
        width: auto;
        justify-content: space-between;
    }

}