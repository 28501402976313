@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #035DA8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header_container__j_FN- {
    background: linear-gradient(#F8ECD9, #F0FBFF);
    display: flex;
    padding: 2em;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.Header_spacer__21LIP {
    display: none;
    flex-grow: 1;
    max-width: 200px;
}

.Header_navContainer__3iet_ {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    flex-grow: 1;
}
.Header_navLink__1v0bo {
    font-size: 1.5em;
    color: #004884;
    font-family: 'Merriweather Sans', sans-serif;
    cursor: pointer;
    text-align: center;
    padding: 0.25em;
}

.Header_logo__2KLTQ {
    height: auto;
    width: calc(100vw - 4em);
    max-width: 400px;
}

@media(min-width: 400px) {
    .Header_navLink__1v0bo {
        font-size: 2em;
    }
}

@media(min-width: 640px) {

    .Header_logo__2KLTQ {
        height: 8em;
        width: auto;
        max-width: unset;
    }

    .Header_navContainer__3iet_ {
        flex-direction: row;
        justify-content: space-around;
    }

}

@media(min-width: 960px) {
    .Header_container__j_FN- {
        padding: 3em 6em;
    }
    .Header_container__j_FN- {
        flex-direction: row;
    }
    .Header_spacer__21LIP {
        display: block;
    }
    .Header_navContainer__3iet_ {
        margin-top: 0;
        width: auto;
    }
}
@media(min-width: 1280px) {
    .Header_container__j_FN- {
        padding: 4em 8em 6em;
    }
    .Header_navContainer__3iet_ {
        width: auto;
        justify-content: space-between;
    }

}
.Hero_container__2uDru {
    /* width: calc(100vw - 16em); */
    background: url('https://images.unsplash.com/photo-1489829024224-f5ef0434a0ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3121&q=80') white;
    background-size: cover;
    /* position: relative;
    left: 8em;
    top: -3em; */
    display: flex;
    flex-direction: column;
}

.Hero_imageText__eRH05 {
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.Hero_heroText__3Is2H {
    width: 100%;
    background: rgba(255, 255, 255, 0.875);
    font-family: 'Merriweather Sans', sans-serif;
}

.Hero_heroText__3Is2H h1 {
    text-align: center;
    color: #004884;
    padding: 0em 1em 0;
    font-size: 1.9em;
}

.Hero_heroText__3Is2H ul {
    color: #333333;
    font-size: 1.375em;
    text-align: left;
    padding: 0 3em;
}
.Hero_heroText__3Is2H li {
    line-height: 1.25em;
    padding: 0.2em 0;
}

.Hero_formContainer__3jnVC {
    background: white;
}

@media(min-width: 480px) {
    .Hero_container__2uDru {
        padding: 2em;
    }

    .Hero_formContainer__3jnVC {
        margin-top: 2em;
    }

}

@media(min-width: 640px) {

    .Hero_heroText__3Is2H h1 {
        padding: 0em 2em 0;
    }
}

@media(min-width: 960px) {
    .Hero_container__2uDru {
        flex-direction: row;
    }

    .Hero_formContainer__3jnVC {
        margin-left: 2em;
        margin-top: 0;
    }
}

@media(min-width: 1280px) {
    .Hero_container__2uDru {
        margin: 0 8em;
        transform: translateY(-2em);
    }
}
.Form_form__3W4A8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em;
}

.Form_form__3W4A8 h1 {
    text-align: center;
    font-weight: normal;
    color: #004884;
    font-size: 1.45em;
    margin: 0 0 1em;
    font-family: 'Merriweather Sans', sans-serif;
}

.Form_inputText__3Bo9M {
    font-family: 'Merriweather Sans', sans-serif;
    display: block;
    box-sizing: border-box;
    border: 1px solid lightgray;
    width: 100%;
    margin: 0.3em 0;
    padding: 0.5em;
    font-size: 1.1em;
    border-radius: 0.2em;
    resize: none;
}

.Form_submitButton__3ywVe {
    background: #E41B17;
    color: white;
    font-weight: bold;
    border-radius: 0.4em;
}

.Form_submitButton__3ywVe:active {
    background-color: #C40602;
}

.Form_submitButton__3ywVe:disabled, .Form_submitButton__3ywVe:active:disabled {
    background-color: #c01c198e;
}

.Statements_container__3_C6H {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: 'Merriweather Sans', Arial, Helvetica, sans-serif;
}

.Statements_container__3_C6H > div {
    width: 100%;
    position: relative;
}

.Statements_header__1b3G6 {
    padding: 0;
}
.Statements_statementContent__1KoR6 {
    padding: 0 2em;
}

@media(min-width: 960px) {
    .Statements_statementContent__1KoR6 {
        padding: 0 10em;
    }
}
.Statement_image__1FI4a {
    mix-blend-mode: soft-light;
    position: absolute;
    background-size: cover;
    opacity: 0.45;
    width: 100%;
    height: 100%;
}

@media(min-width: 960px){

    .Statement_image__1FI4a {
        opacity: 0.6;
    }

    .Statement_fadeLeft__1wYYS {
        -webkit-mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.25) 20%,
        rgba(0, 0, 0, 0.75) 30%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 1));
                mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.25) 20%,
        rgba(0, 0, 0, 0.75) 30%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 1));
    }

    .Statement_statementContent__fadeLeft__3GJPS, .Statement_statementContent__fadeRight__SuxdE {
        width: 60%;
    }
    .Statement_statementContent__fadeLeft__3GJPS {
        float: left;
    }
    .Statement_statementContent__fadeRight__SuxdE {
        float: right;
    }

    .Statement_fadeRight__2xHHX {
        -webkit-mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0.75) 70%,
        rgba(0, 0, 0, 0.25) 80%,
        rgba(0, 0, 0, 0));
                mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0.75) 70%,
        rgba(0, 0, 0, 0.25) 80%,
        rgba(0, 0, 0, 0));  
    }
}
.Footer_container__3GNCk {
    background: linear-gradient(#F0FBFF, #F8ECD9);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #004884;
    font-size: 1.2em;
    font-family: 'Merriweather Sans', sans-serif;
    text-align: center;
}

.Footer_footerText__3-U3c {
    padding: 0 0.5em;
}

.Footer_footerText__3-U3c ul {
    list-style-type: none;
    padding-left: 0;
}
