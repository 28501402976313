.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em;
}

.form h1 {
    text-align: center;
    font-weight: normal;
    color: #004884;
    font-size: 1.45em;
    margin: 0 0 1em;
    font-family: 'Merriweather Sans', sans-serif;
}

.inputText {
    font-family: 'Merriweather Sans', sans-serif;
    display: block;
    box-sizing: border-box;
    border: 1px solid lightgray;
    width: 100%;
    margin: 0.3em 0;
    padding: 0.5em;
    font-size: 1.1em;
    border-radius: 0.2em;
    resize: none;
}

.submitButton {
    background: #E41B17;
    color: white;
    font-weight: bold;
    border-radius: 0.4em;
}

.submitButton:active {
    background-color: #C40602;
}

.submitButton:disabled, .submitButton:active:disabled {
    background-color: #c01c198e;
}
