.container {
    background: linear-gradient(#F0FBFF, #F8ECD9);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #004884;
    font-size: 1.2em;
    font-family: 'Merriweather Sans', sans-serif;
    text-align: center;
}

.footerText {
    padding: 0 0.5em;
}

.footerText ul {
    list-style-type: none;
    padding-left: 0;
}