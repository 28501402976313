.container {
    /* width: calc(100vw - 16em); */
    background: url('https://images.unsplash.com/photo-1489829024224-f5ef0434a0ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3121&q=80') white;
    background-size: cover;
    /* position: relative;
    left: 8em;
    top: -3em; */
    display: flex;
    flex-direction: column;
}

.imageText {
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.heroText {
    width: 100%;
    background: rgba(255, 255, 255, 0.875);
    font-family: 'Merriweather Sans', sans-serif;
}

.heroText h1 {
    text-align: center;
    color: #004884;
    padding: 0em 1em 0;
    font-size: 1.9em;
}

.heroText ul {
    color: #333333;
    font-size: 1.375em;
    text-align: left;
    padding: 0 3em;
}
.heroText li {
    line-height: 1.25em;
    padding: 0.2em 0;
}

.formContainer {
    background: white;
}

@media(min-width: 480px) {
    .container {
        padding: 2em;
    }

    .formContainer {
        margin-top: 2em;
    }

}

@media(min-width: 640px) {

    .heroText h1 {
        padding: 0em 2em 0;
    }
}

@media(min-width: 960px) {
    .container {
        flex-direction: row;
    }

    .formContainer {
        margin-left: 2em;
        margin-top: 0;
    }
}

@media(min-width: 1280px) {
    .container {
        margin: 0 8em;
        transform: translateY(-2em);
    }
}