.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: 'Merriweather Sans', Arial, Helvetica, sans-serif;
}

.container > div {
    width: 100%;
    position: relative;
}

.header {
    padding: 0;
}
.statementContent {
    padding: 0 2em;
}

@media(min-width: 960px) {
    .statementContent {
        padding: 0 10em;
    }
}